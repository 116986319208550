import { toAbsoluteURL, useAppConfig } from '@r-client/shared/util/core';
import {
  INextSEOMetaTagsProps,
  NextSEOMetaTags,
} from '@r-client/shared/util/seo';

const TITLE = 'Republic Capital // Investing in a non-obvious future';
const DESCRIPTION =
  'Republic Capital is driven by a multifaceted value proposition and invests in companies across deep tech, fintech, and web3.';
const getImageUrl = (urlOrigin: string) =>
  toAbsoluteURL(urlOrigin, '/_next-public/republic-capital.png');

export const SEOMetaTags = ({
  title,
  metaTitle,
}: Partial<INextSEOMetaTagsProps>) => {
  const { applicationURLOrigin } = useAppConfig();
  const imageUrl = getImageUrl(applicationURLOrigin);
  return (
    <NextSEOMetaTags
      title={title || TITLE}
      metaTitle={metaTitle || TITLE}
      description={DESCRIPTION}
      image={imageUrl}
      openGraph={{
        title: title || TITLE,
        type: 'website',
        image: imageUrl,
        description: DESCRIPTION,
        url: applicationURLOrigin,
        siteName: title || TITLE,
      }}
      social={{
        twitter: {
          card: 'summary_large_image',
          site: '_rcapital_',
          creator: '_rcapital_',
          title: title || TITLE,
          description: DESCRIPTION,
          image: imageUrl,
          imageAlt: 'republic capital',
        },
      }}
    />
  );
};
